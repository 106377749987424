/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Text, Heading, Link} from "@chakra-ui/react";
import {StackIconsGroup} from "components";
import {ExternalLinkIcon} from "@chakra-ui/icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h2: "h2",
    h3: "h3",
    a: "a",
    h4: "h4",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/featured/letter-shuffle-1.png",
    alt: "imgOne:"
  })), "\n", React.createElement(_components.h2, null, "Summary"), "\n", React.createElement(Heading, {
    as: "div",
    variant: "sec",
    size: "md"
  }, React.createElement(_components.p, null, "This little project was a \"build something\" with TypeScript, Next.js, and\nChakra. The reason for this is no matter how many \"getting starter projects\"\nyou spin up until you build and deploy an idea, no matter how trivial there is\nalways a fair amount of unknown complexity.")), "\n", React.createElement(Link, {
    mb: 6,
    asExternal: true,
    variant: "linkOne",
    href: props.pageContext.frontmatter.link,
    target: "_blank"
  }, props.pageContext.frontmatter.title, React.createElement(ExternalLinkIcon, {
    mx: "2px"
  })), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(Link, {
    mb: 6,
    asExternal: true,
    variant: "linkOne",
    href: props.pageContext.frontmatter.repo,
    target: "_blank"
  }, props.pageContext.frontmatter.title, " - github", React.createElement(ExternalLinkIcon, {
    mx: "2px"
  })), "\n", React.createElement(_components.h3, null, "Stack"), "\n", React.createElement(StackIconsGroup, {
    iconArr: ["chakraui", "gatsby", "netlify", "github", "mdx"]
  }), "\n", React.createElement(_components.h3, null, "Objective"), "\n", React.createElement(_components.p, null, "This project is a bit of a meme. I originally built a ", React.createElement(_components.a, {
    href: "https://codepen.io/LucasZapico/pen/wvMqqwO"
  }, "reorder-string - codepen"), " in 2016 as both a practice project in vanilla.js and to solve my lack of ability to come up with \"good\" usernames."), "\n", React.createElement(_components.p, null, "The resulting shuffler has been my username generator ever since just shuffling my actual name."), "\n", React.createElement(_components.h4, null, "Features Coverage"), "\n", React.createElement(_components.p, null, "From a feature standpoint, it's about as straightforward as they come. Takes in a string and returns a shuffled version of that string."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/letter-shuffle/letter-shuffle-ui-one.gif",
    alt: "letter shuffle tour"
  })), "\n", React.createElement(_components.p, null, "The project architecture is a nice little boilerplate with ready-to-scale support for;"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "advanced theming with Chakra-ui"), "\n", React.createElement(_components.li, null, "MDX"), "\n", React.createElement(_components.li, null, "SVG"), "\n"), "\n", React.createElement(_components.h3, null, "Outcome"), "\n", React.createElement(_components.p, null, "Produced a unique Gatsby site integrated with Netlify CMS enabling easy\nposting. Built a poetry and prose experience in a unique and meaningful\nway"), "\n", React.createElement(_components.h3, null, "Deliverables"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "website"), "\n", React.createElement(_components.li, null, "user experience design"), "\n", React.createElement(_components.li, null, "development"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
